* {
  box-sizing: border-box;
}

html {
  margin: 0;
  padding: 0;
}

body {
  background-color: #2a2d78;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.filepond-container .filepond--wrapper {
  height: 100%;
  min-height: 150px;
  border: 4px dashed gray;
  border-radius: 8px;
}
.filepond-container .filepond--root {
  height: 100% !important;
  min-height: 150px;
  margin: 0;
  cursor: pointer;
}

.filepond-container .filepond--drop-label {
  height: 100% !important;
  cursor: pointer;
}

.filepond-container .filepond--drop-label label {
  cursor: pointer;
}
