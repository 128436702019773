.MuiFormControlLabel-root .MuiTypography-root {
  width: 100%;
}

.MuiCardContent-root:last-child {
  padding-bottom: 16px !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  appearance: textfield;
  -moz-appearance: textfield;
}

.MuiButtonBase-root.Mui-disabled.saveButton {
  background-color: rgb(199, 199, 199) !important;
}

.percentageInput .MuiOutlinedInput-notchedOutline {
  padding: 0 55px 0 8px !important;
}

.percentageInput label.MuiInputLabel-shrink {
  transform: translate(12px, -7px) scale(1) !important;
}

.percentageInput label {
  transform: translate(14px, 12px) scale(1) !important;
}

.paths-card {
  height: -moz-available;
  height: -webkit-fill-available;
  height: fill-available;
}